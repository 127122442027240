body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-content {
  max-width: 500px;
  margin: 0 auto;
  border: solid 0.5px #ddd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.swal2-styled.swal2-confirm {
  background: #FFC107 !important;
}

.swal2-styled.swal2-confirm:focus {
  border-width: 0 !important;
  box-shadow: 0 0 0 2px rgba(235, 189, 41, 0.5) !important;
}

#content-button {
  position: absolute;
  width: 70px;
  height: 0px;
  opacity: 0;
  margin: 20px;
  bottom: 40px;
}

#content-button>div {
  cursor: pointer;
}

#content-button>div>img {
  width: 70px;
  height: 70px;
  margin-top: 8px;
}

#button-container {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  height: 100px;
  bottom: 20px;
  width: 100%;
}

#floating-button {
  display: flex;
  justify-content: flex-end;
}

.btn-circle {
  border-radius: 50%;
}

.btn-app {
  border-radius: 3px;
  /*background-color: #f8f9fa;
  border: 1px solid #ddd;
  color: #6c757d;*/
  border: 1px solid #212529;
  font-size: 12px;
  height: 130px;
  margin: 10px 20px;
  min-width: 130px;
  padding: 25px 5px;
  position: relative;
  text-align: center;
}

.btn-app>.fa, .btn-app>.fab, .btn-app>.fad, .btn-app>.fal, .btn-app>.far, .btn-app>.fas, .btn-app>.ion, .btn-app>.svg-inline--fa {
  display: block;
  font-size: 20px;
}

#image-detail-container {

  position: fixed;
  width: 100%;
  height: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 10000;

}

#image-detail-content {
  width: 500px;
  height: 100vh;
  margin: 0 auto;
  background: white;
  border: solid 0.5px #ddd;
}

@media (max-width: 400px) {

  #main-content,
  #image-detail-container,
  #image-detail-content {
    width: 100%;
    border: solid 0 #ccc;
  }

  #content-button,
  #floating-button {
    right: 20px;
  }
}

@media (max-width: 500px) {

  #main-content,
  #image-detail-container,
  #image-detail-content {
    width: 100%;
    border: solid 0 #ccc;
  }

  #content-button,
  #floating-button {
    right: 0px;
  }
}

@media (min-width: 500px) {
  #floating-button {
    width: 500px;
    margin: 0 auto;
  }
}

.awssld__content div {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.grayscale {
  filter: grayscale(100%);
}